var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("StudentSubHeader", {
        attrs: { "show-marking-period-picker": false },
      }),
      _c(
        "div",
        {
          staticClass:
            "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
        },
        [
          _vm._m(0),
          _vm.student
            ? _c("div", { staticClass: "kt-portlet" }, [
                _c("div", { staticClass: "kt-portlet__body" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-lg-6 col-md-12" }, [
                      _c(
                        "div",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _vm.canCustomize
                            ? _c("StudentAvatar", {
                                attrs: {
                                  student: _vm.student,
                                  "student-avatar": _vm.studentAvatar,
                                },
                              })
                            : _c("StudentAvatar", {
                                attrs: {
                                  student: _vm.student,
                                  "student-avatar": _vm.boringAvatar,
                                },
                              }),
                          _vm.canCustomize
                            ? _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.bottomleft",
                                      modifiers: {
                                        hover: true,
                                        bottomleft: true,
                                      },
                                    },
                                  ],
                                  staticClass: "btn btn-primary btn-wide mt-3",
                                  attrs: { href: "#", title: "Save Avatar" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.save.apply(null, arguments)
                                    },
                                  },
                                },
                                [_vm._v(" Save Changes ")]
                              )
                            : _vm._e(),
                          _vm.canCustomize
                            ? _c(
                                "a",
                                {
                                  staticClass:
                                    "btn btn-primary btn-wide mt-3 ml-2",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.randomize.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" Random ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "col-lg-3 col-md-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("Top Type")]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.topType,
                                expression: "topType",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { disabled: !_vm.canCustomize },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.topType = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          _vm._l(
                            _vm.avatarAssets.topTypes,
                            function (top, index) {
                              return _c("option", { key: `topType_${index}` }, [
                                _vm._v(" " + _vm._s(top) + " "),
                              ])
                            }
                          ),
                          0
                        ),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("Circle Color")]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.circleColor,
                                expression: "circleColor",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { disabled: !_vm.canCustomize },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.circleColor = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          _vm._l(
                            _vm.avatarAssets.hatAndShirtColors,
                            function (color, index) {
                              return _c(
                                "option",
                                { key: `circleColor_${index}` },
                                [_vm._v(" " + _vm._s(color) + " ")]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("Accessories")]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.accessoriesType,
                                expression: "accessoriesType",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { disabled: !_vm.canCustomize },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.accessoriesType = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          _vm._l(
                            _vm.avatarAssets.accessoriesTypes,
                            function (accessory, index) {
                              return _c(
                                "option",
                                { key: `accessoriesType_${index}` },
                                [_vm._v(" " + _vm._s(accessory) + " ")]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("Hair Color")]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.hairColor,
                                expression: "hairColor",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { disabled: !_vm.canCustomize },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.hairColor = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          _vm._l(
                            _vm.avatarAssets.hairColors,
                            function (color, index) {
                              return _c(
                                "option",
                                { key: `hairColor_${index}` },
                                [_vm._v(" " + _vm._s(color) + " ")]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("Facial Hair Type")]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.facialHairType,
                                expression: "facialHairType",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { disabled: !_vm.canCustomize },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.facialHairType = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          _vm._l(
                            _vm.avatarAssets.facialHairTypes,
                            function (facialHair, index) {
                              return _c(
                                "option",
                                { key: `facialHairType_${index}` },
                                [_vm._v(" " + _vm._s(facialHair) + " ")]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("Facial Hair Color")]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.facialHairColor,
                                expression: "facialHairColor",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { disabled: !_vm.canCustomize },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.facialHairColor = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          _vm._l(
                            _vm.avatarAssets.facialHairColors,
                            function (color, index) {
                              return _c(
                                "option",
                                { key: `facialHairColor_${index}` },
                                [_vm._v(" " + _vm._s(color) + " ")]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("Eyebrow Type")]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.eyebrowType,
                                expression: "eyebrowType",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { disabled: !_vm.canCustomize },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.eyebrowType = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          _vm._l(
                            _vm.avatarAssets.eyebrowTypes,
                            function (eyebrow, index) {
                              return _c(
                                "option",
                                { key: `eyebrowType_${index}` },
                                [_vm._v(" " + _vm._s(eyebrow) + " ")]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-lg-3 col-md-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("Top Color")]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.topColor,
                                expression: "topColor",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { disabled: !_vm.canCustomize },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.topColor = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          _vm._l(
                            _vm.avatarAssets.hatColors,
                            function (color, index) {
                              return _c(
                                "option",
                                { key: `topColor_${index}` },
                                [_vm._v(" " + _vm._s(color) + " ")]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("Skin Color")]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.skinColor,
                                expression: "skinColor",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { disabled: !_vm.canCustomize },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.skinColor = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          _vm._l(
                            _vm.avatarAssets.skinColors,
                            function (color, index) {
                              return _c(
                                "option",
                                { key: `skinColor_${index}` },
                                [_vm._v(" " + _vm._s(color) + " ")]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("Mouth")]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.mouthType,
                                expression: "mouthType",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { disabled: !_vm.canCustomize },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.mouthType = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          _vm._l(
                            _vm.avatarAssets.mouthTypes,
                            function (mouth, index) {
                              return _c(
                                "option",
                                { key: `mouthType_${index}` },
                                [_vm._v(" " + _vm._s(mouth) + " ")]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("Eyes")]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.eyeType,
                                expression: "eyeType",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { disabled: !_vm.canCustomize },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.eyeType = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          _vm._l(
                            _vm.avatarAssets.eyeTypes,
                            function (eye, index) {
                              return _c("option", { key: `eyeType_${index}` }, [
                                _vm._v(" " + _vm._s(eye) + " "),
                              ])
                            }
                          ),
                          0
                        ),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("Graphic")]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.graphicType,
                                expression: "graphicType",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { disabled: !_vm.canCustomize },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.graphicType = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          _vm._l(
                            _vm.avatarAssets.graphicTypes,
                            function (graphic, index) {
                              return _c(
                                "option",
                                { key: `graphicType_${index}` },
                                [_vm._v(" " + _vm._s(graphic) + " ")]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("Clothes")]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.clotheType,
                                expression: "clotheType",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { disabled: !_vm.canCustomize },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.clotheType = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          _vm._l(
                            _vm.avatarAssets.clotheTypes,
                            function (clothe, index) {
                              return _c(
                                "option",
                                { key: `clotheType_${index}` },
                                [_vm._v(" " + _vm._s(clothe) + " ")]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("Clothes Color")]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.clotheColor,
                                expression: "clotheColor",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { disabled: !_vm.canCustomize },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.clotheColor = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          _vm._l(
                            _vm.avatarAssets.clotheColors,
                            function (color, index) {
                              return _c(
                                "option",
                                { key: `clotheColor_${index}` },
                                [_vm._v(" " + _vm._s(color) + " ")]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c(
          "div",
          {
            staticClass: "alert alert-light alert-elevate fade show",
            attrs: { role: "alert" },
          },
          [
            _c("div", { staticClass: "alert-icon" }, [
              _c("i", { staticClass: "la la-info-circle kt-font-primary" }),
            ]),
            _c("div", { staticClass: "alert-text" }, [
              _vm._v(
                " In order for students to customize avatars, students must earn a badge that unlocks avatar customization. "
              ),
            ]),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }