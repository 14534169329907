<template>
<div>
    <StudentSubHeader :show-marking-period-picker="false" />

    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <div class="row">
            <div class="col">
                <div
                    class="alert alert-light alert-elevate fade show"
                    role="alert"
                >
                    <div class="alert-icon">
                        <i class="la la-info-circle kt-font-primary" />
                    </div>

                    <div class="alert-text">
                        In order for students to customize avatars, students must earn a badge that unlocks avatar customization.
                    </div>
                </div>
            </div>
        </div>

        <div v-if="student" class="kt-portlet">
            <div class="kt-portlet__body">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div style="text-align: center;">
                            <StudentAvatar
                                v-if="canCustomize"
                                :student="student"
                                :student-avatar="studentAvatar"
                            />
                            <StudentAvatar
                                v-else
                                :student="student"
                                :student-avatar="boringAvatar"
                            />

                            <a
                                v-if="canCustomize"
                                v-b-tooltip.hover.bottomleft
                                href="#"
                                title="Save Avatar"
                                class="btn btn-primary btn-wide mt-3"
                                @click.stop.prevent="save"
                            >
                                Save Changes
                            </a>
                            <a
                                v-if="canCustomize"
                                href="#"
                                class="btn btn-primary btn-wide mt-3 ml-2"
                                @click.stop.prevent="randomize"
                            >
                                Random
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="form-group">
                            <label>Top Type</label>
                            <select
                                v-model="topType"
                                :disabled="!canCustomize"
                                class="form-control"
                            >
                                <option
                                    v-for="(top, index) in avatarAssets.topTypes"
                                    :key="`topType_${index}`"
                                >
                                    {{ top }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Circle Color</label>
                            <select
                                v-model="circleColor"
                                :disabled="!canCustomize"
                                class="form-control"
                            >
                                <option
                                    v-for="(color, index) in avatarAssets.hatAndShirtColors"
                                    :key="`circleColor_${index}`"
                                >
                                    {{ color }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Accessories</label>
                            <select
                                v-model="accessoriesType"
                                :disabled="!canCustomize"
                                class="form-control"
                            >
                                <option
                                    v-for="(accessory, index) in avatarAssets.accessoriesTypes"
                                    :key="`accessoriesType_${index}`"
                                >
                                    {{ accessory }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Hair Color</label>
                            <select
                                v-model="hairColor"
                                :disabled="!canCustomize"
                                class="form-control"
                            >
                                <option
                                    v-for="(color, index) in avatarAssets.hairColors"
                                    :key="`hairColor_${index}`"
                                >
                                    {{ color }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Facial Hair Type</label>
                            <select
                                v-model="facialHairType"
                                :disabled="!canCustomize"
                                class="form-control"
                            >
                                <option
                                    v-for="(facialHair, index) in avatarAssets.facialHairTypes"
                                    :key="`facialHairType_${index}`"
                                >
                                    {{ facialHair }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Facial Hair Color</label>
                            <select
                                v-model="facialHairColor"
                                :disabled="!canCustomize"
                                class="form-control"
                            >
                                <option
                                    v-for="(color, index) in avatarAssets.facialHairColors"
                                    :key="`facialHairColor_${index}`"
                                >
                                    {{ color }}
                                </option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label>Eyebrow Type</label>
                            <select
                                v-model="eyebrowType"
                                :disabled="!canCustomize"
                                class="form-control"
                            >
                                <option
                                    v-for="(eyebrow, index) in avatarAssets.eyebrowTypes"
                                    :key="`eyebrowType_${index}`"
                                >
                                    {{ eyebrow }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="form-group">
                            <label>Top Color</label>
                            <select
                                v-model="topColor"
                                :disabled="!canCustomize"
                                class="form-control"
                            >
                                <option
                                    v-for="(color, index) in avatarAssets.hatColors"
                                    :key="`topColor_${index}`"
                                >
                                    {{ color }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Skin Color</label>
                            <select
                                v-model="skinColor"
                                :disabled="!canCustomize"
                                class="form-control"
                            >
                                <option
                                    v-for="(color, index) in avatarAssets.skinColors"
                                    :key="`skinColor_${index}`"
                                >
                                    {{ color }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Mouth</label>
                            <select
                                v-model="mouthType"
                                :disabled="!canCustomize"
                                class="form-control"
                            >
                                <option
                                    v-for="(mouth, index) in avatarAssets.mouthTypes"
                                    :key="`mouthType_${index}`"
                                >
                                    {{ mouth }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Eyes</label>
                            <select
                                v-model="eyeType"
                                :disabled="!canCustomize"
                                class="form-control"
                            >
                                <option
                                    v-for="(eye, index) in avatarAssets.eyeTypes"
                                    :key="`eyeType_${index}`"
                                >
                                    {{ eye }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Graphic</label>
                            <select
                                v-model="graphicType"
                                :disabled="!canCustomize"
                                class="form-control"
                            >
                                <option
                                    v-for="(graphic, index) in avatarAssets.graphicTypes"
                                    :key="`graphicType_${index}`"
                                >
                                    {{ graphic }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Clothes</label>
                            <select
                                v-model="clotheType"
                                :disabled="!canCustomize"
                                class="form-control"
                            >
                                <option
                                    v-for="(clothe, index) in avatarAssets.clotheTypes"
                                    :key="`clotheType_${index}`"
                                >
                                    {{ clothe }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Clothes Color</label>
                            <select
                                v-model="clotheColor"
                                :disabled="!canCustomize"
                                class="form-control"
                            >
                                <option
                                    v-for="(color, index) in avatarAssets.clotheColors"
                                    :key="`clotheColor_${index}`"
                                >
                                    {{ color }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import Vue from 'vue';

import Types from '../store/Types';
import studentMixins from '../store/mixins/studentMixins';
import portfolioMixins from '../store/mixins/portfolioMixins';
import badgeMixins from '../store/mixins/badgeMixins';
import userMixins from '../store/mixins/userMixins';
import StudentSubHeader from './StudentSubHeader.vue';
import StudentAvatar from '../components/StudentAvatar.vue';
import defaultAvatar from '../lib/defaultAvatar';
import * as network from '../network';

import { topTypes } from '../assets/svg/avatar/top';
import { mouthTypes } from '../assets/svg/avatar/mouth';
import { eyeTypes } from '../assets/svg/avatar/eyes';
import { eyebrowTypes } from '../assets/svg/avatar/eyebrows';
import { clothesType } from '../assets/svg/avatar/clothes';
import { accessoriesTypes } from '../assets/svg/avatar/accessories';
import { facialHairTypes } from '../assets/svg/avatar/facial-hair';
import { GraphicShirtTypes } from '../assets/svg/avatar/graphic-shirt';
import { hairColors, skinColors, hatAndShirtColors } from '../assets/svg/avatar/colors';

const avatarAssets = {
    topTypes: Object.keys(topTypes),
    accessoriesTypes: Object.keys(accessoriesTypes),
    hatColors: Object.keys(hatAndShirtColors),
    hairColors: Object.keys(hairColors),
    facialHairTypes: Object.keys(facialHairTypes),
    facialHairColors: Object.keys(hairColors),
    clotheTypes: Object.keys(clothesType),
    clotheColors: Object.keys(hatAndShirtColors),
    graphicTypes: Object.keys(GraphicShirtTypes),
    eyeTypes: Object.keys(eyeTypes),
    mouthTypes: Object.keys(mouthTypes),
    skinColors: Object.keys(skinColors),
    eyebrowTypes: Object.keys(eyebrowTypes),
    hatAndShirtColors: Object.keys(hatAndShirtColors),
};

export default Vue.extend({
    name: 'StudentAvatarEditor',
    components: {
        StudentSubHeader,
        StudentAvatar,
    },
    mixins: [ studentMixins, portfolioMixins, badgeMixins, userMixins ],
    data() {
        return {
            isCircle: true,
            circleColor: null,
            topType: null,
            accessoriesType: null,
            hairColor: null,
            facialHairType: null,
            facialHairColor: null,
            eyebrowType: null,
            skinColor: null,
            mouthType: null,
            eyeType: null,
            graphicType: null,
            clotheColor: null,
            clotheType: null,
            topColor: null,
        }
    },
    computed: {
        canCustomize() {
            const { studentBadge, isStudentView } = this;
            if (!studentBadge) return false;
            if (isStudentView) {
                return (studentBadge.earnedBadges || []).some((b) => b.unlocksAvatars);
            }
            return true;
        },
        forceStudentView() {
            return this.$store.state.forceStudentView;
        },
        isStudentView() {
            const homeUser = this.$_userMixins_isHomeUser;
            const schoolUser = this.$_userMixins_isSchoolUser;

            if (homeUser) return true;
            if (schoolUser) {
                if (this.forceStudentView) {
                    return true;
                }
                return false;
            }
            return true;
        },
        avatarAssets() { return avatarAssets; },
        studentBadge() {
            return this.$_badgeMixins_getStudentBadgeFromRoute;
        },
        studentAvatar() {
            return {
                topType: this.topType,
                accessoriesType: this.accessoriesType,
                circleColor: this.circleColor,
                eyebrowType: this.eyebrowType,
                skinColor: this.skinColor,
                mouthType: this.mouthType,
                eyeType: this.eyeType,
                graphicType: this.graphicType,
                clotheColor: this.clotheColor,
                clotheType: this.clotheType,
                facialHairColor: this.facialHairColor,
                facialHairType: this.facialHairType,
                hairColor: this.hairColor,
                topColor: this.topColor,
            };
        },
        boringAvatar() {
            return {
                topType: "NoHair",
                accessoriesType: "Blank",
                circleColor: "White",
                eyebrowType: "Default",
                skinColor: "Pale",
                mouthType: "Default",
                eyeType: "Default",
                graphicType: "Bat",
                clotheColor: "Black",
                clotheType: "Hoodie",
                facialHairColor: "BrownDark",
                facialHairType: "Blank",
                hairColor: "Black",
                topColor: "Black"
            }
        },
        student() {
            const student = this.$_studentMixins_getStudentFromRoute;
            if (!student) return null;
            return student;
        },
    },
    mounted() {
        this.populateAvatar();
    },
    methods: {
        randomize() {
            this.circleColor = randomItemFromArray(Object.keys(hatAndShirtColors));
            this.topType = randomItemFromArray(Object.keys(topTypes));
            this.accessoriesType = randomItemFromArray(Object.keys(accessoriesTypes));
            this.facialHairType = randomItemFromArray(Object.keys(facialHairTypes));
            this.clotheType = randomItemFromArray(Object.keys(clothesType));
            this.eyeType = randomItemFromArray(Object.keys(eyeTypes));
            this.eyebrowType = randomItemFromArray(Object.keys(eyebrowTypes));
            this.mouthType = randomItemFromArray(Object.keys(mouthTypes));
            this.skinColor = randomItemFromArray(Object.keys(skinColors));
            this.graphicType = randomItemFromArray(Object.keys(GraphicShirtTypes));
            this.hairColor = randomItemFromArray(Object.keys(hairColors));
            this.facialHairColor = randomItemFromArray(Object.keys(hairColors));
            this.topColor = randomItemFromArray(Object.keys(hatAndShirtColors));
            this.clotheColor = randomItemFromArray(Object.keys(hatAndShirtColors));
        },
        populateAvatar() {
            if (!this.student) return;
            const { studentAvatar, studentId} = this.student;

            const avatar = defaultAvatar(studentId);
            Object.keys(avatar).forEach(key => {
                this[key] = avatar[key];
            });

            Object.keys(studentAvatar).forEach(key => {
                if (studentAvatar[key] !== null) {
                    this[key] = studentAvatar[key];
                }
            });

        },
        save() {
            const { studentAvatar, showError, $store } = this;
            const { studentId } = this.student;
            const { schoolTermId, schoolId } = this.$store.state.user.school;
            const params = {
                url: {schoolTermId, schoolId, studentId},
                body: { studentAvatar },
            };
            network.students.saveStudentAvatar(params, function(err, res) {
                if (err) return showError(err);
                window.location.reload();
            });
        },
    },
});

function randomItemFromArray(array) {
    return array[Math.floor(Math.random() * array.length)];
}

</script>

<style scoped>
.row > .col-md-12 > div {
    margin-left: 10%;
    width: 80%;
}
@media screen and (max-width: 1024px) {
    .row > .col-md-12 > div {
        margin-left: 25%;
        width: 50%;
        margin-bottom: 40px;
    }
}
</style>